<template>
  <v-container>
    <div class="text-right">
      <v-btn color="info" class="mb-5" @click="(edit = false), (dialog = true)"
        >Uusi remontti</v-btn
      >
    </div>

    <v-row v-if="renovations.length > 0" class="justify-center">
      <v-col sm="12" md="8" lg="6" cols="12">
        <v-timeline>
          <v-timeline-item v-for="(renovation, i) in renovations" :key="i" small>
            <template #opposite>
              <span :class="`headline font-weight-bold primary--text`">
                {{ months[new Date(renovation.date).getMonth()] }}
                {{ new Date(renovation.date).getFullYear() }}</span
              >
            </template>

            <v-card>
              <v-card-title :class="`headline font-weight-light`">{{
                renovation.name
              }}</v-card-title>
              <v-card-text
                >{{ renovation.description }}
                <span>
                  <v-icon
                    class="ml-1"
                    title="Muokkaa"
                    small
                    @click="
                      editRenovation(renovation),
                        (edit = true),
                        (dialog = true),
                        (editId = renovation._id)
                    "
                    >mdi-pencil</v-icon
                  >
                </span>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-col>
    </v-row>

    <v-row v-else class="justify-center">
      <h2>Ei luotuja remontteja</h2>
    </v-row>

    <!-- Dialog add renovation -->

    <v-dialog v-model="dialog" width="500">
      <v-card>
        <!-- New -->
        <v-card-title v-if="!edit"> Lisää uusi remontti </v-card-title>

        <!-- Edit -->
        <v-card-title v-if="edit"> Muokkaa remonttia </v-card-title>

        <v-card-text>
          <v-form ref="form">
            <v-text-field
              v-model="renovation.name"
              label="Nimi"
              :rules="required"
              dense
              outlined
            ></v-text-field>

            <v-menu ref="menu" v-model="menu" :close-on-content-click="false" max-width="290">
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFormatted"
                  dense
                  outlined
                  v-bind="attrs"
                  :rules="required"
                  v-on="on"
                  append-icon="mdi-calendar"
                  label="Remontin valmistumisaika"
                  v-prevent-manual-input
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="renovation.date"
                first-day-of-week="1"
                type="month"
                @input="menu = false"
              ></v-date-picker>
            </v-menu>

            <v-textarea
              v-model="renovation.description"
              label="Kuvaus"
              placeholder="Kuvaus remontista"
              :rules="required"
              dense
              outlined
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn color="error" outlined @click="dialog = false"> Poistu </v-btn>
          <v-spacer></v-spacer>
          <!-- New -->
          <v-btn v-if="!edit" color="info" @click="createRenovation()"> Luo </v-btn>
          <v-spacer v-if="edit"></v-spacer>
          <!-- Edit -->
          <v-btn v-if="edit" color="error" @click="deleteRenovation(renovation)"> Poista </v-btn>
          <v-btn v-if="edit" color="info" @click="saveRenovation(renovation)"> Tallenna </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import mixins from "../../mixins/mixins";
import axiosMethods from "../../mixins/axios";

export default {
  title: "Kohteen remontit",

  mixins: [mixins],

  data: () => ({
    menu: false,
    edit: false,
    editId: "",
    dialog: false,
    dateFormatted: "",
    renovations: [],
    months: [
      "Tammikuu",
      "Helmikuu",
      "Maaliskuu",
      "Huhtikuu",
      "Toukokuu",
      "Kesäkuu",
      "Heinäkuu",
      "Elokuu",
      "Syyskuu",
      "Lokakuu",
      "Marraskuu",
      "Joulukuu",
    ],
    renovation: {
      name: "",
      date: "",
      description: "",
    },
    required: [(v) => !!v || "Pakollinen kenttä"],
    positiveNumber: [(v) => v > 0 || "Anna positiivinen luku"],
  }),

  watch: {
    "renovation.date"() {
      if (this.renovation.date) {
        this.dateFormatted =
          this.months[new Date(this.renovation.date).getMonth()] +
          " " +
          new Date(this.renovation.date).getFullYear();
      }
    },
  },

  async mounted() {
    const res = await axiosMethods.get(
      `/api/v1/renovation/getRenovations/${this.$route.params.id}`
    );
    this.renovations = res.data.renovations;
  },

  methods: {
    async createRenovation() {
      try {
        if (this.$refs.form.validate()) {
          const res = await axiosMethods.post(
            `/api/v1/renovation/createRenovation/${this.$route.params.id}`,
            this.renovation
          );
          this.renovations.unshift(res.data.renovation);
          this.dialog = false;
          this.renovation = {
            name: "",
            date: new Date(new Date()).toISOString().substring(0, 10),
            description: "",
          };
          this.showPopup("Uusi remontti luotu", "success");
        }
      } catch (err) {
        this.showPopup("Remontin lisääminen ei onnistunut", "error");
      }
    },

    async deleteRenovation(item) {
      let answer = confirm(`Haluatko varmasti poistaa remontin ${item.name}?`);

      if (answer) {
        try {
          await axiosMethods.delete(`/api/v1/renovation/deleteRenovation/${item.id}`);
          let renovations = this.renovations.filter((el) => {
            return el._id != item._id;
          });
          this.renovations = renovations;
          this.dialog = false;
          this.showPopup("Remontti poistettu", "success");
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },

    editRenovation(item) {
      item.date = new Date(item.date).toISOString().substring(0, 10);
      this.renovation = item;
    },

    async saveRenovation() {
      if (this.$refs.form.validate()) {
        try {
          await axiosMethods.update(`/api/v1/renovation/editRenovation/`, {
            id: this.renovation._id,
            renovation: this.renovation,
          });

          this.dialog = false;

          this.showPopup("Remontti tallennettu", "success");
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },
  },
};
</script>

<style scoped>
@media (max-width: 768px) {
  .v-application .headline {
    font-size: 13px !important;
    padding: 8px;
  }

  .v-card__text {
    font-size: 10px;
    padding: 8px;
  }

  .container {
    padding: 0;
    margin: 0;
  }
}
</style>
