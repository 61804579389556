<template>
  <receipt-table source="apartment"></receipt-table>
</template>

<script>
import ReceiptTable from "./ReceiptTable";

export default {
  title: "Kohteen kulut",

  components: {
    ReceiptTable,
  },
};
</script>

<style scoped></style>
