<template>
  <note-template model="apartment"></note-template>
</template>

<script>
import NoteTemplate from "../Note/NoteTemplate.vue";

export default {
  title: "Muistiinpanot",

  components: {
    NoteTemplate,
  },
};
</script>

<style></style>
